<template>
  <div class="card">
    <div class="card-body" v-if="type != false">
      <div class="container mb-5 mt-3">
        <div class="row d-flex align-items-baseline">
          <div class="col-xl-9">
            <p style="color: #7e8d9f; font-size: 20px">Quote</p>
          </div>
        </div>
        <div class="container">
          <div class="col-md-12">
            <div class="text-center">
              <img src="/logo.png" alt="logo" height="80px" />
            </div>
          </div>
          <div class="row">
            <div class="col-xl-8">
              <ul class="list-unstyled">
                <li class="text-muted">
                  To: <span style="color: #8f8061">{{ request.name }}</span>
                </li>
                <li class="text-muted">{{ request.address }}</li>
              </ul>
            </div>
            <div class="col-xl-4">
              <p class="text-muted">Quote</p>
              <ul class="list-unstyled">
                <li class="text-muted">
                  <span class="fw-bold">Creation Date: </span>
                  {{ request.created_at }}
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div class="row my-2 mx-1 justify-content-center">
            <div class="col-md-7 mb-4 mb-md-0">
              <template v-if="type == 'painting-and-cleaning'">
                <p class="fw-bold m-0">Service: {{ request.service }}</p>
                <p class="m-0">Address: {{ request.address }}</p>
                <p class="m-0">Size: {{ request.size }} square meters</p>
                <p class="m-0">Date: {{ request.date }}</p>
                <p class="m-0">{{ request.comments }}</p>
              </template>
              <template v-if="type == 'movers'">
                <p class="fw-bold m-0">Moving Services</p>
                <p class="m-0">From: {{ request.from }}</p>
                <p class="m-0">To: {{ request.to }}</p>
                <p class="m-0">Date: {{ request.date }}</p>
                <p class="m-0">{{ request.comments }}</p>
              </template>
              <template v-if="type == 'legal-and-surveying'">
                <p class="fw-bold m-0">Service: {{ request.service }}</p>
                <p class="m-0">{{ request.comments }}</p>
              </template>
            </div>
            <div class="col-md-5 mb-4 mb-md-0">
              <h5 class="mb-2">
                <span class="align-middle">KES {{ quote.amount }}</span>
              </h5>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-xl-8">
              <p class="ms-3" v-if="quote.comments">{{ quote.comments }}</p>
            </div>
            <div class="col-xl-4">
              <ul class="list-unstyled">
                <li class="text-muted ms-3">
                  <span class="text-black me-4">SubTotal</span>KES
                  {{ quote.amount }}
                </li>
              </ul>
              <p class="text-black float-start">
                <span class="text-black me-3"> Total Amount</span
                ><span style="font-size: 25px">KES {{ quote.amount }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      default() {
        return false;
      },
    },
    quote: {
      default() {
        return {};
      },
    },
    request: {
      default() {
        return {};
      },
    },
  },
};
</script>
