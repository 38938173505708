<template>
  <div>
    <div v-if="!loading">
      <div class="row mb-3">
        <div class="col-md-12">
          <a href="#" class="btn btn-success float-end"
            >Status: {{ request.status }}</a
          >
          <h3>Request #{{ request.id }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <h4>Details</h4>
              <div class="row">
                <div class="col-md-12">
                  <label>Name</label>
                  <p>{{ request.name }}</p>
                </div>
                <div class="col-md-12">
                  <label>Email</label>
                  <p>{{ request.email }}</p>
                </div>
                <div class="col-md-12">
                  <label>Phone Number</label>
                  <p>{{ request.phone_number }}</p>
                </div>
                <div class="col-md-12">
                  <label>Service</label>
                  <p>{{ request.service }}</p>
                </div>
                <div class="col-md-12">
                  <label>Comments</label>
                  <p>{{ request.comments }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <h4>Generate Quote</h4>
              <form @submit.prevent="show_preview = true">
                <div class="form-group">
                  <label>Amount</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="quote.amount"
                    placeholder="Quote Amount"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Comments</label>
                  <textarea
                    v-model="quote.comments"
                    required
                    class="form-control"
                    placeholder="Comments"
                  ></textarea>
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">Preview</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
    <modal :show="show_preview" @close="show_preview = false" width="large">
      <template slot="header">Quote Preview</template>
      <service-quote :quote="quote" :request="request" type="legal-and-surveying" />
      <div class="container mt-4">
        <div class="row">
          <div class="col-md-12 text-end">
            <a href="#" class="btn btn-primary" @click.prevent="submit">Send Quote</a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ServiceQuote from "@/components/ServiceQuote";

export default {
  components: {
    ServiceQuote,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      request: {},
      quote: {},
      show_preview: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/admin/legal-surveying/${this.$route.params.id}`)
        .then((response) => {
          this.request = response.data.request;
          this.loading = false;
        });
    },

    submit() {
      this.loading = true
      this.show_preview = false

      let data = {
        action: 'send',
        amount: this.quote.amount,
        comments: this.quote.comments
      }

      this.$axios.put(`/api/v1/admin/legal-surveying/${this.$route.params.id}`, data).then(() => {
        this.$swal('success', 'Email Sent', 'Success')
        this.loading = false
      }).catch(() => {
        this.$swal('error', 'There was an error sending the email.', 'Error')
        this.loading = false
      })
    }
  },
};
</script>
